import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import CustomAvatar from './customAvatar';
import { Button, Typography, AppBar, Toolbar, IconButton, MenuIcon } from '../UIComponents';
import { signOut } from '../../services/authService';
import * as actions from '../../store/user/action-types';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	menuButton: {
		marginRight: 36
	},
	hide: {
		display: 'none'
	},
	title: {
		flexGrow: 1
	}
}));

function Navbar(props) {
	const { handleDrawerOpen, open } = props;
	const classes = useStyles();
	const user = useSelector((state) => state.user.userData);

	const dispatch = useDispatch();

	const signOutUser = () => {
		signOut();
		dispatch({ type: actions.DELETE_USER });
		navigate('/app/login');
	};

	const navbarClassNames = `${classes.appBar} ${open ? classes.appBarShift : ''}`;
	const iconButtonClassNames = `${classes.menuButton} ${open ? classes.hide : ''}`;

	return (
		<AppBar position="fixed" className={navbarClassNames}>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={handleDrawerOpen}
					edge="start"
					className={iconButtonClassNames}
				>
					<MenuIcon />
				</IconButton>
				<Typography variant="h6" noWrap className={classes.title}>
					SurvivalBoxes Backoffice
				</Typography>
				{user && <CustomAvatar image={user.picture} name={user.name} role={user.roles[1]} />}
				<Button color="inherit" onClick={signOutUser}>
					Logout
				</Button>
			</Toolbar>
		</AppBar>
	);
}

export default Navbar;
