import React from 'react';
import { Container, Typography, CircularProgress } from './UIComponents';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	rootLoading: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		background: theme.palette.background.paper
	},
	spacing: {
		marginBottom: theme.spacing(2)
	}
}));

const Loader = ({ message }) => {
	const classes = useStyles();

	return (
		<Container className={classes.rootLoading}>
			<CircularProgress className={classes.spacing} />
			<Typography className={classes.spacing} variant="caption" component="div">
				{message}
			</Typography>
		</Container>
	);
};

export default Loader;
