import React, { useEffect, useState, useReducer } from 'react';
import { Container, Card, CardContent, Typography } from '../UIComponents';
import { useSelector, useDispatch } from 'react-redux';
import CustomSelect from '../inputs/customSelect';
import CustomTextField from '../inputs/customTextField';
import CustomCheckBox from '../inputs/customCheckBox';
import CustomTable from '../utilities/customTable';
import DefaultButton from '../buttons/defaultButton';
import SuccessModal from '../modals/successModal';
import ConfirmModal from '../modals/confirmModal';
import Loader from '../loader';
import ErrorModal from '../modals/errorModal';
import { makeStyles } from '@material-ui/core/styles';
import { addCouponAction, addPromoCodeAction, getCouponList } from '../../services/fetchService';
import { doExportToExcel, generateCouponsList } from '../../services/storeUpdater';
import * as actions from '../../store/coupons/action-types';

const useStyles = makeStyles({
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)'
	},
	title: {
		fontSize: 14
	},
	pos: {
		marginBottom: 12
	},
	flex: {
		display: 'flex',
		alignItems: 'center'
	}
});

const headCells = [
	{ id: 'name', numeric: false, disablePadding: true, label: 'Coupon Name' },
	{ id: 'percent_off', numeric: false, disablePadding: false, label: 'Percentage Off (%)' },
	{ id: 'amount_off', numeric: false, disablePadding: false, label: 'Amount Off ($)' },
	{ id: 'times_redeemed', numeric: false, disablePadding: false, label: 'Times Redeemed' },
	{ id: 'promotionCodes', numeric: false, disablePadding: false, label: 'Promotion Codes' },
	{ id: 'valid', numeric: false, disablePadding: false, label: 'Is Valid' }
];

const cellIndex = ['name', 'percent_off', 'amount_off', 'times_redeemed', 'promotionCodes', 'valid'];

const CouponsAndPromoCodes = (props) => {
	const classes = useStyles();
	const coupons = useSelector((state) => state.coupons.couponsData);

	const dispatch = useDispatch();

	const [successMessage, setSuccessMessage] = useState('');
	const [loadingAddCoupon, setLoadingAddCoupon] = useState(false);
	const [loadingAddPromoCode, setLoadingAddPromoCode] = useState(false);
	const [loadingCoupons, setLoadingCoupons] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [createCoupon, setCreateCoupon] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [createPromoCode, setCreatePromoCode] = useState(true);
	const [couponsOptions, setCouponsOptions] = useState([1]);

	const [couponForm, setCouponForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
		name: '',
		toApply: {
			M1: true,
			M3: true,
			M6: true,
			M12: true
		}
	});

	const [promoCodeForm, setPromoCodeForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
		code: '',
		coupon: '',
		percentageOff: 0,
		amountOff: 0,
		toApply: {
			M1: true,
			M3: true,
			M6: true,
			M12: true
		}
	});

	const [tableSorts, setTableSorts] = useReducer((state, newState) => ({ ...state, ...newState }), {
		order: 'asc',
		orderBy: 'percent_off',
		page: 0
	});

	useEffect(() => {
		if (coupons) {
			const couponsOptions = coupons
				.map((coupon) => {
					let itemText = '';
					if (promoCodeForm.percentageOff > 0) {
						itemText = `${coupon.name} - ${coupon.percent_off}%`;
					} else {
						itemText = `${coupon.name} - $${coupon.amount_off}`;
					}

					return {
						text: itemText,
						value: coupon.id,
						percentageOff: coupon.percent_off,
						amountOff: coupon.amount_off
					};
				})
				.filter((coupon) => {
					if (promoCodeForm.percentageOff > 0) {
						return coupon.percentageOff === Number(promoCodeForm.percentageOff);
					} else {
						return coupon.amountOff === Number(promoCodeForm.amountOff);
					}
				});
			setCouponsOptions(couponsOptions);
		}
	}, [promoCodeForm.percentageOff, promoCodeForm.amountOff, coupons]);

	useEffect(() => {
		const couponList = async () => {
			setLoadingCoupons(true);
			const couponListResponse = await getCouponList();
			if (couponListResponse) {
				setLoadingCoupons(false);

				if (typeof couponListResponse === 'string') {
					setErrorMessage(couponListResponse);
				} else {
					dispatch({
						type: actions.SAVE_COUPONS,
						payload: generateCouponsList(couponListResponse)
					});
				}
			}
		};

		if (!coupons) {
			couponList();
		}
	}, [dispatch, coupons]);

	const handleCouponForm = (event) => {
		const name = event.target.name;
		let value = event.target.value;

		setCouponForm({
			[name]: value.trim()
		});
	};

	const inputError = false;

	const handlePromoCodeForm = (event) => {
		const name = event.target.name;
		let value = event.target.value;

		if (name !== 'coupon') value = value.toUpperCase();

		setPromoCodeForm({
			[name]: value.trim()
		});
	};

	const checkAvailbleCoupons = () => {
		if (Number(promoCodeForm.percentageOff) > 0 && couponsOptions.length === 0) {
			setShowConfirmModal(true);
		}
	};

	const handleAddCouponAction = async () => {
		setLoadingAddCoupon(true);
		let amountController = '';
		let amountItem = '';
		if (promoCodeForm.amountOff > 0) {
			amountController = 'amount_off';
			amountItem = 'amountOff';
		} else if (promoCodeForm.percentageOff > 0) {
			amountController = 'percent_off';
			amountItem = 'percentageOff';
		}

		const couponData = {
			coupon: {
				applies_to: {
					products: [process.env.GATSBY_PRODUCTS]
				},
				brandId: process.env.GATSBY_BRANDID,
				currency: 'usd',
				duration: 'once',
				metadata: {},
				name: couponForm.name,
				[amountController]: Number(promoCodeForm[amountItem]),
				toApply: couponForm.toApply
			}
		};

		const addCouponResponse = await addCouponAction(couponData);

		if (addCouponResponse) {
			setLoadingAddCoupon(false);

			if (typeof addCouponResponse === 'string') {
				setErrorMessage(addCouponResponse);
			} else {
				setShowSuccessModal(true);
				setSuccessMessage('Coupon has been created successfully');
				coupons.push(addCouponResponse);

				dispatch({
					type: actions.SAVE_COUPONS,
					payload: coupons
				});

				setCreateCoupon(false);
				setCreatePromoCode(true);
				resetForms();
			}
		}
	};

	const handlePromoCodeAction = async () => {
		setLoadingAddPromoCode(true);
		const promoCodeData = {
			promotion: {
				brandId: process.env.GATSBY_BRANDID,
				code: promoCodeForm.code,
				coupon: promoCodeForm.coupon,
				metadata: {},
				toApply: {
					M1: true,
					M3: true,
					M6: true,
					M12: true
				}
			}
		};

		const addPromoCodeResponse = await addPromoCodeAction(promoCodeData);

		if (addPromoCodeResponse) {
			setLoadingAddPromoCode(false);

			if (typeof addPromoCodeResponse === 'string') {
				setErrorMessage(addPromoCodeResponse);
			} else {
				const newCouponsList = coupons.map((coupon) => {
					if (coupon.id === addPromoCodeResponse.coupon) {
						const newPromoCodes = coupon.promotionCodes.push(addPromoCodeResponse);
						return {
							...coupon,
							promotionCodes: newPromoCodes
						};
					} else {
						return coupon;
					}
				});

				dispatch({
					type: actions.SAVE_COUPONS,
					payload: newCouponsList
				});

				setShowSuccessModal(true);
				setSuccessMessage('Promo code has been created successfully');
				resetForms();
			}
		}
	};

	const resetForms = () => {
		setCouponForm({
			name: '',
			toApply: {
				M1: true,
				M3: true,
				M6: true,
				M12: true
			}
		});

		setPromoCodeForm({
			code: '',
			coupon: '',
			percentageOff: 0,
			amountOff: 0,
			toApply: {
				M1: true,
				M3: true,
				M6: true,
				M12: true
			}
		});
	};

	const handleSuccessModal = () => {
		setShowSuccessModal(false);
	};

	const handleChangeForms = () => {
		setCreateCoupon(true);
		setCreatePromoCode(false);
		setShowConfirmModal(false);
	};

	const exportToExcel = () => {
		const excelCoupons = coupons.map((coupon) => {
			return {
				...coupon,
				promotionCodes: coupon.promotionCodes.join(', ')
			};
		});

		doExportToExcel(cellIndex, excelCoupons, 'Coupons List');
	};

	const couponList = async () => {
		setLoadingCoupons(true);
		const couponListResponse = await getCouponList();
		if (couponListResponse) {
			setLoadingCoupons(false);

			if (typeof couponListResponse === 'string') {
			} else {
				dispatch({
					type: actions.SAVE_COUPONS,
					payload: generateCouponsList(couponListResponse)
				});
			}
		}
	};

	const handleAnotherCoupon = (event) => {
		const checked = event.target.checked;

		if ((checked && promoCodeForm.percentageOff > 0) || promoCodeForm.amountOff > 0) {
			setCreateCoupon(true);
			setCreatePromoCode(false);
		}
	};

	const handleTableSorts = (data) => {
		const newTableSorts = Object.assign(tableSorts, data);

		setTableSorts(newTableSorts);
	};

	const successModal = showSuccessModal && (
		<SuccessModal openModal={showSuccessModal} message={successMessage} handleCloseModal={handleSuccessModal} />
	);

	const errorModal = errorMessage !== '' && (
		<ErrorModal
			openModal={errorMessage !== ''}
			message={errorMessage}
			handleCloseModal={() => setErrorMessage('')}
		/>
	);

	let dataContent = null;

	const disabledPromoCodeButton = promoCodeForm.code === '' || promoCodeForm.coupon === '';
	const disabledCouponButton = couponForm.name === '';

	if (loadingCoupons) {
		dataContent = <Loader message="Loading last coupons." />;
	} else {
		dataContent = (
			<Container>
				{successModal}
				{errorModal}
				<Card className={classes.root}>
					{createCoupon && (
						<>
							<CardContent>
								<Typography variant="h5" component="h2">
									Add Coupon
								</Typography>
								<div className={classes.flex}>
									<div className={classes.flex}>
										<CustomTextField
											name="name"
											label="Coupon name"
											value={couponForm.name}
											handleChange={handleCouponForm}
											error={inputError}
										/>
										{promoCodeForm.percentageOff > 0 && (
											<CustomTextField
												name="percentageOff"
												label="Percentage Off (%)"
												value={promoCodeForm.percentageOff}
												handleChange={null}
												error={inputError}
												type="number"
											/>
										)}
										{promoCodeForm.amountOff > 0 && (
											<CustomTextField
												name="amountOff"
												label="Amount Off ($)"
												value={promoCodeForm.amountOff}
												handleChange={null}
												error={inputError}
												type="number"
											/>
										)}
									</div>
								</div>
							</CardContent>

							<div className={classes.flex} style={{ width: 400 }}>
								<DefaultButton
									title="Cancel"
									buttonAction={() => {
										setCreatePromoCode(true);
										setCreateCoupon(false);
									}}
									disabled={loadingAddCoupon}
									color="secondary"
								/>
								<DefaultButton
									title="Create Coupon"
									buttonAction={handleAddCouponAction}
									loading={loadingAddCoupon}
									disabled={loadingAddCoupon || disabledCouponButton}
								/>
							</div>
						</>
					)}
					{createPromoCode && (
						<>
							<CardContent>
								<Typography variant="h5" component="h2">
									Add Promo Code
								</Typography>
								<div className={classes.flex}>
									<CustomTextField
										name="code"
										label="Promo Code Name"
										value={promoCodeForm.code}
										handleChange={handlePromoCodeForm}
										error={inputError}
									/>
									<div className={classes.flex} style={{ flexDirection: 'column' }}>
										<CustomTextField
											name="percentageOff"
											label="Percentage Off (%)"
											value={promoCodeForm.percentageOff}
											handleChange={handlePromoCodeForm}
											error={inputError}
											type="number"
											disabled={promoCodeForm.amountOff > 0}
										/>
										<CustomTextField
											name="amountOff"
											label="Amount Off ($)"
											value={promoCodeForm.amountOff}
											handleChange={handlePromoCodeForm}
											error={inputError}
											type="number"
											disabled={promoCodeForm.percentageOff > 0}
										/>
									</div>
									{couponsOptions && (
										<CustomSelect
											name="coupon"
											label="Select Coupon to assing"
											options={couponsOptions}
											value={promoCodeForm.coupon}
											handleChange={handlePromoCodeForm}
											handleOpen={checkAvailbleCoupons}
										/>
									)}
									<CustomCheckBox
										name="createCoupon"
										checked={createCoupon}
										label="Create new coupon? (Set percentage off before)"
										handleChange={handleAnotherCoupon}
									/>
								</div>
							</CardContent>
							<div className={classes.flex} style={{ width: 400 }}>
								<DefaultButton
									title="Create Promo Code"
									buttonAction={handlePromoCodeAction}
									loading={loadingAddPromoCode}
									disabled={disabledPromoCodeButton || loadingAddPromoCode}
								/>
							</div>
						</>
					)}
				</Card>
			</Container>
		);
	}

	const confirmModal = showConfirmModal && (
		<ConfirmModal
			openModal={showConfirmModal}
			handleCloseModal={() => setShowConfirmModal(false)}
			handleConfirmAction={handleChangeForms}
			message="Currently not have Coupons with this percentage off, do you want to create a new Coupon?"
		/>
	);

	return (
		<>
			{confirmModal}
			{dataContent}
			{!loadingCoupons && coupons && (
				<>
					<br />
					<CustomTable
						rows={coupons}
						tableTitle="Coupons List"
						headCells={headCells}
						cellIndex={cellIndex}
						handleClickRow={null}
						refreshList={couponList}
						tableSorts={tableSorts}
						handleTableSorts={handleTableSorts}
						currentBatchNumber={null}
						exportToExcel={exportToExcel}
						type="small"
						hideButton={true}
					/>
				</>
			)}
		</>
	);
};

export default CouponsAndPromoCodes;
