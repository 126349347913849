import React, { useEffect, useReducer, useState } from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import Filters from '../filters';
import EmptyList from '../emptyList';
import Loader from '../utilities/loader';
import CustomTable from '../utilities/customTable';
import { getShippingList } from '../../services/fetchService';
import {
	removeLocalStorageItem,
	getLocalStorageItem,
	setLocalStorageItem,
	mergeDataToLocalStorage
} from '../../services/localStorageService';
import { setBoxFeatureInfo, filteringData, doExportToExcel } from '../../services/storeUpdater';
import * as CONSTANTS from '../../constants/universal';
import { CUSTOM_TABLE_SORTS, BOX_LIST_FILTERS } from '../../constants/localStorage';
import * as actions from '../../store/boxes/action-types';

const headCells = [
	{ id: 'seeMore', numeric: false, disablePadding: false, label: 'More' },
	{ id: 'ownerEmail', numeric: false, disablePadding: true, label: 'Owner Email' },
	{ id: 'boxTheme', numeric: false, disablePadding: false, label: 'Current Theme Number' },
	{ id: 'subscriber', numeric: false, disablePadding: true, label: 'Subscriber' },
	{ id: 'id', numeric: false, disablePadding: true, label: 'Box Id' },
	{ id: 'deliverDate', numeric: false, disablePadding: true, label: 'Deliver Date' },
	{ id: 'status', numeric: false, disablePadding: false, label: 'Box Status' },
	{ id: 'product', numeric: false, disablePadding: false, label: 'Product' },
	{ id: 'addOns', numeric: false, disablePadding: false, label: 'AddOns' },
	{ id: 'wellcomeBox', numeric: false, disablePadding: false, label: 'Welcome Box' },
	{ id: 'batchNumber', numeric: true, disablePadding: false, label: 'Batch Number' },
	{ id: 'folderItem', numeric: true, disablePadding: false, label: 'Folder Item' }
];

const cellIndex = [
	'ownerEmail',
	'boxTheme',
	'subscriber',
	'id',
	'deliverDate',
	'status',
	'product',
	'addOns',
	'wellcomeBox',
	'batchNumber',
	'folderItem'
];

function BoxList() {
	const boxes = useSelector((state) => state.boxes.boxesData);
	const storedBatch = useSelector((state) => state.boxes.batch);

	const dispatch = useDispatch();

	const [batch, setBatch] = useState(CONSTANTS.FILTER_BATCHES[1].value);
	const [boxList, setBoxList] = useState([]);
	const [formState, setStateForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
		status: '',
		ownerEmail: '',
		tracking: '',
		withAddOns: '',
		product: ''
	});
	const [tableSorts, setTableSorts] = useReducer((state, newState) => ({ ...state, ...newState }), {
		order: 'asc',
		orderBy: 'ownerEmail',
		page: 0
	});
	const [currentBatchNumber, setCurrentBatchNumber] = useState('');

	const [loadingShippingList, setLoadingShippingList] = useState(false);
	const [errorMessage, setErrorMessage] = useState();

	useEffect(() => {
		const batchNumbers = CONSTANTS.getBatchNumbers();
		const selectedBatch = CONSTANTS.FILTER_BATCHES.find((item) => item.value === batch).text.split(': ')[1];

		const batchText = selectedBatch ? selectedBatch.trim() : false;

		const currentBatchNumber = batchText && batchNumbers.find((batch) => batch.batchRange.trim() === selectedBatch);

		batchText && setCurrentBatchNumber(currentBatchNumber);
	}, [batch]);

	useEffect(() => {
		const sorts = getLocalStorageItem(CUSTOM_TABLE_SORTS);
		const lastListFilters = getLocalStorageItem(BOX_LIST_FILTERS);

		if (sorts) {
			setTableSorts({
				...sorts
			});
		}

		if (lastListFilters) {
			setStateForm({
				...lastListFilters
			});
		}
	}, []);

	useEffect(() => {
		const doFilteringBoxes = () => {
			const filteredBoxes = filteringData(formState, boxes);

			setBoxList(filteredBoxes);
		};

		if (boxes) {
			doFilteringBoxes();
		}
	}, [dispatch, boxes, formState]);

	const handleChange = (event) => {
		const name = event.target.name;
		let value = event.target.value;

		if (name === 'batch') {
			setBatch(value);
		} else {
			if (name === 'ownerEmail') {
				value = value.trim().toLowerCase();
			}

			mergeDataToLocalStorage(BOX_LIST_FILTERS, {
				[name]: value
			});

			setTableSorts({
				order: 'asc',
				orderBy: 'ownerEmail',
				page: 0
			});

			setStateForm({
				[name]: value
			});
		}
	};

	const getBoxes = async () => {
		setLoadingShippingList(true);

		let variable = {
			filter: {}
		};

		if (batch !== '') {
			variable.filter.batch = batch;
		} else {
			setCurrentBatchNumber(false);
		}

		if (formState.ownerEmail !== '') {
			variable.filter.ownerEmail = formState.ownerEmail;
		}

		if (formState.status !== '') {
			variable.filter.status = formState.status;
		}

		const shippingListResponse = await getShippingList(variable);

		if (shippingListResponse) {
			setLoadingShippingList(false);
			if (typeof shippingListResponse === 'string') {
				setErrorMessage(shippingListResponse);
			} else {
				const newShippingList = setBoxFeatureInfo(shippingListResponse, currentBatchNumber.batchNumber);

				if (newShippingList) {
					setBoxList(newShippingList);

					dispatch({
						type: actions.SAVE_BOXES,
						payload: {
							boxes: newShippingList,
							batch: batch
						}
					});
				}
			}
		}
	};

	const clearFilters = () => {
		setStateForm({
			status: '',
			ownerEmail: '',
			tracking: '',
			withAddOns: '',
			product: ''
		});

		setTableSorts({
			order: 'asc',
			orderBy: 'ownerEmail',
			page: 0
		});

		removeLocalStorageItem(CUSTOM_TABLE_SORTS);
		removeLocalStorageItem(BOX_LIST_FILTERS);
	};

	const refreshBoxList = () => {
		getBoxes();
	};

	const goToBoxInfo = (event, boxDetails) => {
		navigate(`/app/box-details/?boxId=${boxDetails.id}&backRoute=box-list-shipping`, {
			state: { boxId: boxDetails.id, backRoute: '/app/box-list-shipping' }
		});
	};

	const handleTableSorts = (data) => {
		const newTableSorts = Object.assign(tableSorts, data);

		setLocalStorageItem(CUSTOM_TABLE_SORTS, newTableSorts);

		setTableSorts(newTableSorts);
	};

	const exportToExcel = () => {
		doExportToExcel(cellIndex, boxList, currentBatchNumber.batchRange);
	};

	let loader = null;
	let table = null;
	let message = null;

	if (loadingShippingList) {
		loader = <Loader message="Loading boxes, please wait..." />;
	} else {
		if (errorMessage) {
		} else {
			message = null;
			if (boxList.length > 0 && storedBatch === batch) {
				table = (
					<CustomTable
						rows={boxList}
						tableTitle="Box List"
						headCells={headCells}
						cellIndex={cellIndex}
						handleClickRow={goToBoxInfo}
						refreshList={refreshBoxList}
						tableSorts={tableSorts}
						handleTableSorts={handleTableSorts}
						currentBatchNumber={currentBatchNumber}
						exportToExcel={exportToExcel}
					/>
				);
			} else {
				table = <EmptyList getData={getBoxes} />;
			}
		}
	}

	return (
		<div>
			<Filters
				handleChange={handleChange}
				batch={batch}
				formState={formState}
				filter="boxes"
				clearFilters={clearFilters}
				getData={getBoxes}
				simple={true}
			/>
			{table}
			{loader}
			{message}
		</div>
	);
}

export default BoxList;
