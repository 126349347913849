import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Avatar, Tooltip } from '../UIComponents';

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11
	}
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		'& > *': {
			margin: theme.spacing(1)
		}
	},
	text: {
		textAlign: 'center'
	}
}));

function CustomAvatar({ image, name, role }) {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<LightTooltip
				title={
					<>
						<span>Hi, {name}</span>
						<br />
						<span className={classes.text}>Role: {role}</span>
					</>
				}
				placement="left"
			>
				<Avatar alt={name} src={image} />
			</LightTooltip>
		</div>
	);
}

export default CustomAvatar;
