import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel } from '../UIComponents';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
	formControl: {
		marginLeft: theme.spacing(1),
		minWidth: 200
	}
}));

function CustomCheckBox({ name, checked, label, handleChange }) {
	const classes = useStyles();

	return (
		<FormControlLabel
			className={classes.formControl}
			control={<Checkbox checked={checked} onChange={handleChange} name={name} color="primary" />}
			label={label}
		/>
	);
}

export default CustomCheckBox;
