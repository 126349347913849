export const addCoupon = `mutation AddCoupon($coupon: CouponInput!){
    addCoupon(coupon : $coupon){
        id
        amount_off
        created
        currency
        duration
        duration_in_months
        livemode
        max_redemptions
        name
        percent_off
        redeem_by
        times_redeemed
        valid
        brandId
    }
}`;
