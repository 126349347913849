import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Typography,
	Toolbar,
	Checkbox,
	SyncIcon,
	Button,
	List,
	ListItemText,
	DescriptionIcon
} from '../UIComponents';
import DefaultButton from '../buttons/defaultButton';

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white
	},
	body: {
		fontSize: 14
	}
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover
		}
	}
}))(TableRow);

const StyledTableSortLabel = withStyles((theme) => ({
	root: {
		color: theme.palette.common.white,
		'&:active': {
			color: theme.palette.common.white
		},
		'&:hover': {
			color: theme.palette.common.white
		}
	},
	icon: {
		color: theme.palette.common.white
	},
	active: {
		color: theme.palette.common.white + ' !important'
	}
}))(TableSortLabel);

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const { headCells, order, classes, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<StyledTableRow>
				{headCells.map((headCell) => (
					<StyledTableCell
						key={headCell.id}
						align="center"
						padding="default"
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<StyledTableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</StyledTableSortLabel>
					</StyledTableCell>
				))}
			</StyledTableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
	headCell: PropTypes.array
};

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1)
	},
	highlight:
		theme.palette.type === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85)
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
			  },
	title: {
		flex: '1 1 100%',
		display: 'flex',
		alignItems: 'center'
	},
	buttonTitle: {
		marginLeft: theme.spacing(5)
	}
}));

const EnhancedTableToolbar = ({
	tableTitle,
	numberBoxes,
	refreshList,
	hideRefresh,
	currentBatchNumber,
	exportToExcel
}) => {
	const classes = useToolbarStyles();

	return (
		<Toolbar className={classes.root}>
			<Typography className={classes.title} variant="h6" id="tableTitle" component="div" color="primary">
				{currentBatchNumber && `Batch Number: ${currentBatchNumber.batchNumber} - `} {tableTitle} ({numberBoxes}
				)
				<div className={classes.buttonTitle}>
					{!hideRefresh && (
						<DefaultButton
							title="Refresh"
							hideWrapper={true}
							startIcon={<SyncIcon />}
							buttonAction={refreshList}
						/>
					)}
				</div>
				<div className={classes.buttonTitle}>
					<DefaultButton
						title="Export to Excel"
						hideWrapper={true}
						startIcon={<DescriptionIcon />}
						buttonAction={exportToExcel}
					/>
				</div>
			</Typography>
		</Toolbar>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		backgroundColor: theme.palette.common.white,
		boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.3)'
	},
	table: {
		minWidth: 750
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	}
}));

function CustomTable({
	rows,
	tableTitle,
	headCells,
	cellIndex,
	handleClickRow,
	refreshList,
	tableSorts,
	handleTableSorts,
	hideRefresh,
	currentBatchNumber,
	exportToExcel,
	type,
	hideButton
}) {
	const classes = useStyles();
	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('photosQty');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(50);

	useEffect(() => {
		if (tableSorts) {
			setOrderBy(tableSorts.orderBy);
			setOrder(tableSorts.order);
			setPage(tableSorts.page);
			setRowsPerPage(tableSorts.rowsPerPage ? tableSorts.rowsPerPage : 50);
		}
	}, [orderBy, order, page, rowsPerPage, tableSorts]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);

		handleTableSorts({
			orderBy: property,
			order: isAsc ? 'desc' : 'asc'
		});
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);

		handleTableSorts({
			page: newPage
		});
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));

		handleTableSorts({
			rowsPerPage: parseInt(event.target.value, 10)
		});

		setPage(0);
	};

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	return (
		<div className={classes.root}>
			<div className={classes.paper} style={type === 'small' ? { paddingRight: '5%', paddingLeft: '5%' } : {}}>
				<EnhancedTableToolbar
					tableTitle={tableTitle}
					numberBoxes={rows.length}
					refreshList={refreshList}
					hideRefresh={hideRefresh}
					currentBatchNumber={currentBatchNumber}
					exportToExcel={exportToExcel}
				/>
				<TableContainer>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size="small"
						aria-label="a dense table"
					>
						{headCells && (
							<EnhancedTableHead
								classes={classes}
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								rowCount={rows.length}
								headCells={headCells}
							/>
						)}
						<TableBody>
							{stableSort(rows, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									const cellItems = cellIndex.map((cell) => {
										const dateItems = ['deliverDate', 'createdAt', 'memberSince'];
										if (dateItems.includes(cell)) {
											return moment(row[cell]).utc().format('ddd, MMM Do YYYY');
										} else {
											return row[cell];
										}
									});
									return (
										<StyledTableRow hover className={classes.row} tabIndex={-1} key={index}>
											{hideButton ? null : (
												<StyledTableCell>
													<Button
														variant="contained"
														color="primary"
														disableElevation
														onClick={(event) => handleClickRow(event, row)}
													>
														More
													</Button>
												</StyledTableCell>
											)}
											{cellItems.map((cell, index) => {
												let align = null;

												if (typeof cell === 'number' || typeof cell === 'boolean') {
													align = 'center';
												} else {
													align = 'left';
												}

												if (typeof cell === 'boolean') {
													return (
														<StyledTableCell key={index} align={align} padding="checkbox">
															<Checkbox checked={cell} color="primary" />
														</StyledTableCell>
													);
												} else if (Array.isArray(cell)) {
													return (
														<StyledTableCell key={index} align={align}>
															<List>
																{cell.length > 0 &&
																	cell.map((addOn, index) => {
																		return (
																			<ListItemText
																				key={index}
																				primary={addOn.name}
																				secondary={
																					addOn.tracking ? (
																						<>
																							Tracking number:{' '}
																							<a
																								href={
																									addOn.tracking
																										.trackingLink
																								}
																								target="_blank"
																								rel="noreferrer"
																							>
																								{
																									addOn.tracking
																										.trackingId
																								}
																							</a>
																						</>
																					) : (
																						'Tracking number: N/A'
																					)
																				}
																				style={{
																					minWidth: 100,
																					whiteSpace: 'nowrap'
																				}}
																			/>
																		);
																	})}
															</List>
														</StyledTableCell>
													);
												} else {
													return (
														<StyledTableCell key={index} align={align}>
															<div
																style={{
																	minWidth: 100,
																	whiteSpace: 'nowrap'
																}}
															>
																{cell}
															</div>
														</StyledTableCell>
													);
												}
											})}
										</StyledTableRow>
									);
								})}
							{emptyRows > 0 && (
								<StyledTableRow>
									<StyledTableCell colSpan={6} />
								</StyledTableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				{rows.length > 0 && (
					<TablePagination
						rowsPerPageOptions={[50, 100, 150]}
						component="div"
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				)}
			</div>
		</div>
	);
}

export default CustomTable;
