import { ROLES } from '../constants/universal';

export const setStackByUser = (roles) => {
	let normalRoutes = [];
	let adminRoutes = [];
	const isPoweredUser = roles.some(
		(rol) => rol === ROLES.ADMINS || rol === ROLES.SUPPORT || rol === ROLES.DEVELOPERS
	);
	const isAdmin = roles.some((rol) => rol === ROLES.ADMINS || rol === ROLES.DEVELOPERS);

	const isShipping = roles.some((rol) => rol === ROLES.OPERATOR);

	normalRoutes = [
		{
			path: '/app/box-list',
			text: 'Box List'
		},
		{
			path: '/app/subscription-list',
			text: 'Subscription List'
		}
	];

	if (isPoweredUser) {
		adminRoutes = [
			{
				path: '/app/coupons-and-promo-codes',
				text: 'Coupons and Promo Codes'
			}
		];

		if (isAdmin) {
			adminRoutes.push({
				path: '/app/admin-user-claims',
				text: 'Admin Users'
			});
		}
	}

	if (isShipping) {
		normalRoutes = [
			{
				path: '/app/box-list-shipping',
				text: 'Box List'
			}
		];
	}

	return [normalRoutes, adminRoutes];
};
