import React from 'react';
import { Typography, Divider } from '../UIComponents';

function ItemHeader({ title }) {
	return (
		<>
			<Typography component="h5" variant="h6" align="center" color="primary">
				{title}
			</Typography>
			<br />
			<Divider />
			<br />
		</>
	);
}

export default ItemHeader;
