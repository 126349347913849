import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../components/utilities/layout';
import { navigate } from 'gatsby';
import { isLoggedIn } from '../services/authService';
import * as actions from '../store/user/action-types';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		const checkLoggedIn = async () => {
			const storedUser = await isLoggedIn();
			if (storedUser) {
				dispatch({ type: actions.SAVE_USER, payload: storedUser });
			} else {
				navigate('/app/login');
			}
		};

		checkLoggedIn();
	}, [dispatch]);

	return (
		<Layout location={location}>
			<Component {...rest} location={location} />
		</Layout>
	);
};

export default PrivateRoute;
