export const addPromotionCode = `mutation AddPromotionCode($promotion: PromotionCodeInput!){
    addPromotionCode(promotion: $promotion){
        id
        object
        active
        code
        coupon{
            id
        }
        created
        customer
        expires_at
        livemode
        max_redemptions
        metadata
        restrictions{
            first_time_transaction
            minimum_amount
            minimum_amount_currency
        }
        times_redeemed
        brandId
        toApply{
            M1
            M3
            M6
            M12
        }
    }
}`;
