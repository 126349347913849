import { graphqlServiceAuthSauce } from './httpService';
import { shippingList } from './graphql/shippingList';
import { subscriptionList } from './graphql/subscriptionList';
import { updateBoxBackOffice } from './graphql/updateBoxBackOffice';
import { addCoupon } from './graphql/addCoupon';
import { addCustomClaims } from './graphql/addCustomClaims';
import { couponList } from './graphql/couponList';
import { addPromotionCode } from './graphql/addPromotionCode';

export const getShippingList = async (variables) => {
	try {
		const shippingListResponse = await graphqlServiceAuthSauce(shippingList, variables);
		console.log(shippingListResponse);
		if (shippingListResponse.data && shippingListResponse.data.data) {
			const shippingList = shippingListResponse.data.data.shippingList.sort((a, b) => {
				if (a.ownerEmail < b.ownerEmail) {
					return -1;
				} else {
					return 1;
				}
			});

			return shippingList;
		} else {
			return shippingListResponse.data.errors[0].message;
		}
	} catch (error) {
		return error;
	}
};

export const getSubscriptionList = async (variables) => {
	try {
		const subscriptionListResponse = await graphqlServiceAuthSauce(subscriptionList, variables);
		console.log(subscriptionListResponse);

		if (subscriptionListResponse.data && subscriptionListResponse.data.data) {
			const subscriptionList = subscriptionListResponse.data.data.subscriptionList;
			return subscriptionList;
		} else {
			return subscriptionListResponse.data.errors[0].message;
		}
	} catch (error) {
		return error;
	}
};

export const updateBoxData = async (data) => {
	try {
		const updatedBoxResponse = await graphqlServiceAuthSauce(updateBoxBackOffice, data);
		console.log(updatedBoxResponse);

		if (updatedBoxResponse.data && updatedBoxResponse.data.data) {
			const updatedBox = updatedBoxResponse.data.data.updateBoxBackOffice;
			return updatedBox;
		} else {
			return false;
		}
	} catch (error) {
		return false;
	}
};

export const addCustomClaimsAction = async (variables) => {
	try {
		const addCustomClaimsResponse = await graphqlServiceAuthSauce(addCustomClaims, variables);
		console.log(addCustomClaimsResponse);
		if (addCustomClaimsResponse.data && addCustomClaimsResponse.data.data) {
			const addCustomClaims = addCustomClaimsResponse.data.data.addCustomClaims;

			return addCustomClaims;
		} else {
			return addCustomClaimsResponse.data.errors[0].message;
		}
	} catch (error) {
		return error.message;
	}
};

export const getCouponList = async () => {
	try {
		const couponListResponse = await graphqlServiceAuthSauce(couponList);
		console.log(couponListResponse);

		if (couponListResponse.data && couponListResponse.data.data) {
			if (couponListResponse.data.data.couponList) {
				const couponList = couponListResponse.data.data.couponList;
				return couponList;
			} else {
				return couponListResponse.data.errors[0].message;
			}
		} else {
			return couponListResponse.data.errors[0].message;
		}
	} catch (error) {
		return error.message;
	}
};

export const addCouponAction = async (couponData) => {
	try {
		const addCouponResponse = await graphqlServiceAuthSauce(addCoupon, couponData);
		console.log(addCouponResponse);

		if (addCouponResponse.data && addCouponResponse.data.data) {
			if (addCouponResponse.data.data.addCoupon) {
				const addCoupon = addCouponResponse.data.data.addCoupon;
				return addCoupon;
			} else {
				return addCouponResponse.data.errors[0].message;
			}
		} else {
			return addCouponResponse.data.errors[0].message;
		}
	} catch (error) {
		return error.message;
	}
};

export const addPromoCodeAction = async (promoCodeData) => {
	try {
		const addPromotionCodeResponse = await graphqlServiceAuthSauce(addPromotionCode, promoCodeData);
		console.log(addPromotionCodeResponse);

		if (addPromotionCodeResponse.data && addPromotionCodeResponse.data.data) {
			if (addPromotionCodeResponse.data.data.addPromotionCode) {
				const addCoupon = addPromotionCodeResponse.data.data.addPromotionCode;
				return addCoupon;
			} else {
				return addPromotionCodeResponse.data.errors[0].message;
			}
		} else {
			return addPromotionCodeResponse.data.errors[0].message;
		}
	} catch (error) {
		return error.message;
	}
};
