import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField } from '../UIComponents';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
		minWidth: 240
	}
}));

function CustomTextField({ name, label, value, handleChange, disabled, error, type }) {
	const classes = useStyles();

	return (
		<FormControl className={classes.formControl}>
			<TextField
				name={name}
				label={label}
				value={value}
				onChange={handleChange}
				disabled={disabled}
				error={error}
				type={type ? type : 'text'}
				min={0}
			/>
		</FormControl>
	);
}

export default CustomTextField;
