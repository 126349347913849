import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Box, Typography, Alert } from '../UIComponents';
import DefaultButton from '../buttons/defaultButton';
import { signInWithGmail, isLoggedIn, signOut, checkIfAuthrized } from '../../services/authService';
import SurvivalBoxesLogo from '../../../static/assets/survivalbox-logo-mobile.png';
import * as actions from '../../store/user/action-types';
import { ROLES } from '../../constants/universal';

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		height: '80vh',
		justifyContent: 'center'
	},
	spacing: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4)
	},
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '30%',
		backgroundColor: theme.palette.common.white,
		boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.3)'
	},
	header: {
		marginBottom: theme.spacing(2)
	},
	avatar: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(2),
		width: 120
	}
}));

function Login() {
	const classes = useStyles();
	const user = useSelector((state) => state.user.userData);
	const dispatch = useDispatch();
	const [loginError, setLoginError] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (user) {
			const isAdmin = user.roles.some(
				(rol) => rol === ROLES.ADMINS || rol === ROLES.SUPPORT || rol === ROLES.DEVELOPERS
			);
			const isShipping = user.roles.some((rol) => rol === ROLES.OPERATOR);

			if (isAdmin) {
				navigate('/app/box-list');
			}

			if (isShipping) {
				navigate('/app/box-list-shipping');
			}
		}
	}, [user]);

	const handleSubmit = async () => {
		setLoginError('');
		setLoading(true);
		const signedInResponse = await signInWithGmail();
		if (signedInResponse) {
			setLoading(false);
			if (typeof signedInResponse === 'boolean') {
				const user = await isLoggedIn();
				const isAuthorized = checkIfAuthrized(user);
				if (isAuthorized) {
					dispatch({ type: actions.SAVE_USER, payload: user });
				} else {
					signOut();
					setLoginError(
						`Your account is not "onewayecommerce.com / softdev.team" domain or your user role is not authorized.`
					);
				}
			} else {
				setLoginError(signedInResponse);
			}
		}
	};

	return (
		<div>
			<div className={classes.paper}>
				<div className={classes.wrapper}>
					<img className={classes.avatar} src={SurvivalBoxesLogo} alt="logo" />
					<Typography className={classes.header} component="h1" variant="h5">
						Backoffice System
					</Typography>
					<DefaultButton
						title="Login with Google"
						buttonAction={handleSubmit}
						loading={loading}
						disabled={loading}
					/>
					{loginError.length > 0 && (
						<Alert className={classes.spacing} severity="error">
							{loginError}
						</Alert>
					)}
				</div>
			</div>
			<Box mt={5}>
				<Copyright />
			</Box>
		</div>
	);
}

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="https://material-ui.com/">
				Oneway E-Commerce
			</Link>{' '}
			2020
			{'.'}
		</Typography>
	);
}

export default Login;
