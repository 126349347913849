import React, { useState } from 'react';
import { Container, Card, CardContent, Typography } from '../UIComponents';
import CustomSelect from '../inputs/customSelect';
import CustomTextField from '../inputs/customTextField';
import DefaultButton from '../buttons/defaultButton';
import SuccessModal from '../modals/successModal';
import ErrorModal from '../modals/errorModal';
import { makeStyles } from '@material-ui/core/styles';
import { ROLES_OPTIONS } from '../../constants/universal';
import { addCustomClaimsAction } from '../../services/fetchService';

const useStyles = makeStyles({
	root: {
		minWidth: 275
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)'
	},
	title: {
		fontSize: 14
	},
	pos: {
		marginBottom: 12
	}
});

const AdminUserClaims = (props) => {
	const classes = useStyles();
	const [customClaims, setCustomClaims] = useState(['Users']);
	const [selectedClaim, setSelectedClaim] = useState('Users');
	const [userId, setUserId] = useState('');
	const [loadingCustomClaim, setLoadingCustomClaim] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [inputError, setInputError] = useState(false);

	const handleCustomClaims = (event) => {
		const value = event.target.value;

		setSelectedClaim(value);

		if (customClaims.length === 2) {
			customClaims.pop();
		}
		setCustomClaims((old) => [...old, value]);
	};

	const postUserCustomClaims = async () => {
		if (userId !== '') {
			setLoadingCustomClaim(true);

			//	if (userId !== userData.user_id) {
			const variables = {
				roles: customClaims,
				userId: userId.trim()
			};

			const addCustomClaimsResponse = await addCustomClaimsAction(variables);

			if (addCustomClaimsResponse) {
				setLoadingCustomClaim(false);
				if (typeof addCustomClaimsResponse === 'string') {
					setErrorMessage(addCustomClaimsResponse);
				} else {
					setShowSuccessModal(true);
				}
			}
		} else {
			setInputError(true);
		}
	};

	const handleSuccessModal = () => {
		setShowSuccessModal(false);
		setUserId('');
	};

	const successModal = showSuccessModal && (
		<SuccessModal
			openModal={showSuccessModal}
			message="Claim has been changed successfully."
			handleCloseModal={handleSuccessModal}
		/>
	);

	const errorModal = errorMessage !== '' && (
		<ErrorModal
			openModal={errorMessage !== ''}
			message={errorMessage}
			handleCloseModal={() => setErrorMessage('')}
		/>
	);

	return (
		<Container>
			{successModal}
			{errorModal}
			<Card className={classes.root}>
				<CardContent>
					<Typography variant="h5" component="h2">
						Add Claims:
					</Typography>
					<CustomTextField
						name="userId"
						label="User Id"
						value={userId}
						handleChange={(event) => {
							setUserId(event.target.value);
							setInputError(false);
						}}
						error={inputError}
					/>
					<CustomSelect
						name="customClaims"
						label="Select Custom Claim"
						options={ROLES_OPTIONS}
						value={selectedClaim}
						handleChange={handleCustomClaims}
					/>
					<DefaultButton
						title="Change Custom Claim"
						buttonAction={postUserCustomClaims}
						loading={loadingCustomClaim}
						disabled={loadingCustomClaim}
					/>
				</CardContent>
			</Card>
		</Container>
	);
};

export default AdminUserClaims;
