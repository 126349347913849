import React, { useEffect, useState } from 'react';
import { navigate, Link } from 'gatsby';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
	Container,
	Grid,
	Typography,
	ListItemText,
	ListItem,
	CircularProgress,
	List,
	Fab,
	ArrowBackIcon,
	Alert,
	Card,
	CardActions,
	CardContent,
	Button
} from '../UIComponents';
import ItemHeader from '../utilities/itemHeader';
import { stringCapitalization } from '../../services/dataValidationService';
import { setBoxFeatureInfo } from '../../services/storeUpdater';
import { getShippingList } from '../../services/fetchService';
import { useStyles } from '../../styles/components/_boxDetails';
import TrackingModal from '../modals/trackingModal';

const BoxDetails = (props) => {
	const classes = useStyles();
	const searchParams = props.location.search.split('=');
	const boxId = searchParams[1].split('&')[0] || props.location.state.boxId;
	const paramId = searchParams[3] ? `=${searchParams[3]}` : '';
	const backRoute = searchParams[2] ? `/app/${searchParams[2]}${paramId}` : '' || props.location.state.backRoute;
	const boxes = useSelector((state) => state.boxes.boxesData);
	const [boxDetails, setBoxDetails] = useState(null);
	const [itemName, setItemName] = useState([]);

	const [loadingBoxDetails, setLoadingBoxDetails] = useState(false);
	const [openTrackingModal, setOpenTrackingModal] = useState(false);
	const [selectedAddon, setSelectedAddon] = useState('');

	useEffect(() => {
		const getBoxDetail = async () => {
			setLoadingBoxDetails(true);

			const boxDetailsResponse = await getShippingList({
				filter: {
					id: boxId
				}
			});

			if (boxDetailsResponse) {
				const boxDetails = setBoxFeatureInfo(boxDetailsResponse);

				setLoadingBoxDetails(false);

				setBoxDetails(boxDetails[0]);
				const itemName = Object.keys(boxDetails[0]);
				setItemName(itemName);
			}
		};

		if (boxes) {
			const storedBoxDetails = boxes.find((box) => box.id === boxId);

			if (storedBoxDetails) {
				setBoxDetails(storedBoxDetails);
				const itemName = storedBoxDetails ? Object.keys(storedBoxDetails) : [];
				setItemName(itemName);
			} else {
				if (!boxDetails) {
					getBoxDetail();
				}
			}
		} else {
			if (!boxDetails) {
				getBoxDetail();
			}
		}
	}, [boxDetails, boxes, boxId]);

	const getDetailItem = (itemName) => {
		let listItemFormatted = null;
		const newItemName = stringCapitalization(itemName);

		if (typeof boxDetails[itemName] === 'boolean') {
			const item = boxDetails[itemName] ? 'Yes' : 'No';

			listItemFormatted = <ListItemText primary={newItemName} secondary={item} />;
		} else {
			const formattedDate = moment(boxDetails[itemName]).utc().format('MMMM, Do YYYY');

			switch (itemName) {
				case 'deliverDate':
					listItemFormatted = <ListItemText primary={newItemName} secondary={formattedDate} />;
					break;
				case 'createdAt':
					listItemFormatted = <ListItemText primary={newItemName} secondary={formattedDate} />;
					break;
				case 'modifiedAt':
					listItemFormatted = <ListItemText primary={newItemName} secondary={formattedDate} />;
					break;
				case 'tracking':
					listItemFormatted = (
						<ListItemText
							primary={newItemName}
							secondary={
								boxDetails[itemName]['trackingId'] === 'N/A' ? (
									'None'
								) : (
									<a href={boxDetails[itemName]['trackingLink']} target="_blank" rel="noreferrer">
										{boxDetails[itemName]['trackingId']}
									</a>
								)
							}
						/>
					);
					break;
				case 'subscriptionId':
					listItemFormatted = (
						<ListItemText
							primary={newItemName}
							secondary={
								boxDetails[itemName] === '0' ? (
									'None'
								) : (
									<Link
										to={`/app/subscription-details/?subscriptionId=${boxDetails.subscriptionId}&backRoute=box-details/?boxId=${boxDetails.id}`}
									>
										{boxDetails[itemName]}
									</Link>
								)
							}
						/>
					);
					break;
				case 'addOns':
					break;
				case 'trackingLink':
					break;
				default:
					listItemFormatted = (
						<ListItemText
							primary={newItemName}
							secondary={boxDetails[itemName]}
							target="_blank"
							rel="noreferrer"
						/>
					);
					break;
			}
		}

		return listItemFormatted;
	};

	let boxDetailsComponent = null;

	const trackingModal = openTrackingModal && (
		<TrackingModal
			openModal={openTrackingModal}
			handleCloseModal={() => setOpenTrackingModal(false)}
			boxDetails={boxDetails}
			selectedAddon={selectedAddon}
		/>
	);

	if (loadingBoxDetails) {
		boxDetailsComponent = (
			<Container className={classes.rootLoading}>
				<CircularProgress className={classes.spacing} />
				<Typography className={classes.spacing} variant="caption" component="div">
					Loading Box data...
				</Typography>
			</Container>
		);
	} else {
		if (boxDetails) {
			boxDetailsComponent = (
				<Container>
					<div className={classes.root}>
						<Fab
							size="small"
							color="primary"
							aria-label="add"
							onClick={() => navigate(backRoute ? backRoute : '/app/box-list')}
						>
							<ArrowBackIcon />
						</Fab>
						<Grid container spacing={3} justify="center">
							<Grid item md={4} xs={12}>
								<ItemHeader title="Box Details" />
								<List>
									{itemName.map((name, index) => {
										if (
											typeof boxDetails[name] === 'string' ||
											typeof boxDetails[name] === 'boolean' ||
											name === 'tracking'
										) {
											return <ListItem key={index}>{getDetailItem(name)}</ListItem>;
										} else {
											return null;
										}
									})}
								</List>
							</Grid>
							<Grid item md={5} xs={12}>
								<div className={classes.boxesContainer}>
									<ItemHeader title="Shipping Address" />
									<List>
										{Object.keys(boxDetails.boxRoot.shippingAddress)
											.sort((a, b) => {
												if (a > b) {
													return 1;
												} else {
													return -1;
												}
											})
											.map((name, index) => {
												const newItemName = stringCapitalization(name);
												const content = boxDetails.boxRoot.shippingAddress[name];
												return (
													<ListItemText
														key={index}
														primary={newItemName}
														secondary={newItemName === 'Geometry' ? null : content}
													/>
												);
											})}
									</List>
								</div>
								<div className={classes.boxesContainer}>
									<ItemHeader title="AddOns" />
									{boxDetails.addOns.length > 0 ? (
										boxDetails.addOns.map((addOn, index) => {
											return (
												<Card className={classes.spacing} key={index}>
													<CardContent>
														<Typography>{addOn.name}</Typography>
														<Typography variant="body2" component="p">
															Tracking number:{' '}
															{addOn.tracking ? (
																<a
																	href={addOn.tracking.trackingLink}
																	target="_blank"
																	rel="noreferrer"
																>
																	{addOn.tracking.trackingId}
																</a>
															) : (
																'N/A'
															)}
														</Typography>
													</CardContent>
													{!addOn.tracking && (
														<CardActions>
															<Button
																size="small"
																onClick={() => {
																	setOpenTrackingModal(true);
																	setSelectedAddon({
																		priceId: addOn.priceId,
																		name: addOn.name
																	});
																}}
															>
																Add Tracking
															</Button>
														</CardActions>
													)}
												</Card>
											);
										})
									) : (
										<Alert severity="error">This box not have Addons.</Alert>
									)}
								</div>
								<div className={classes.boxesContainer}>
									<ItemHeader title="Theme numbers" />
									<List>
										<ListItemText primary={`Current Theme Number: ${boxDetails?.boxTheme}`} />
									</List>
								</div>
							</Grid>
						</Grid>
					</div>
				</Container>
			);
		}
	}

	return (
		<>
			{trackingModal}
			{boxDetailsComponent}
		</>
	);
};

export default BoxDetails;
