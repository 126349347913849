import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { navigate } from 'gatsby';
import {
	Container,
	Grid,
	Alert,
	ListItemText,
	ListItem,
	List,
	Fab,
	ArrowBackIcon,
	CircularProgress,
	Typography
} from '../UIComponents';
import ItemHeader from '../utilities/itemHeader';
import { stringCapitalization } from '../../services/dataValidationService';
import { getSubscriptionList } from '../../services/fetchService';
import { useStyles } from '../../styles/components/_subscriptionDetails';
import { Button } from '@material-ui/core';

const SubscriptionDetails = (props) => {
	const classes = useStyles();
	const searchParams = props.location.search.split('=');
	const subscriptionId = searchParams[1].split('&')[0] || props.location.state.subscriptionId;
	const paramId = searchParams[3] ? `=${searchParams[3]}` : '';
	const backRoute = searchParams[2] ? `/app/${searchParams[2]}${paramId}` : '' || props.location.state.backRoute;
	const subscriptions = useSelector((state) => state.subscriptions.subscriptionsData);
	const [subscriptionDetails, setSubscriptionDetails] = useState(null);
	const [itemName, setItemName] = useState([]);
	const [loadingSubscription, setLoadingSubscription] = useState(false);

	useEffect(() => {
		const getSubscriptionDetail = async () => {
			setLoadingSubscription(true);

			const subscriptionsListResponse = await getSubscriptionList({
				filter: {
					id: subscriptionId
				}
			});

			if (subscriptionsListResponse) {
				const subscriptionDetails = subscriptionsListResponse[0];
				setLoadingSubscription(false);

				setSubscriptionDetails(subscriptionDetails);
				const itemName = Object.keys(subscriptionDetails).reverse();

				setItemName(itemName);
			}
		};

		if (subscriptions) {
			const subscriptionDetails = subscriptions.find((subscription) => subscription.id === subscriptionId);
			if (subscriptionDetails) {
				setSubscriptionDetails(subscriptionDetails);
				const itemName = Object.keys(subscriptionDetails).reverse();
				setItemName(itemName);
			}
		} else {
			getSubscriptionDetail();
		}
	}, [subscriptions, subscriptionId]);

	const getDetailItem = (itemName) => {
		let listItemFormatted = null;
		const newItemName = stringCapitalization(itemName);

		if (typeof subscriptionDetails[itemName] === 'boolean') {
			const item = subscriptionDetails[itemName] ? 'Yes' : 'No';

			listItemFormatted = <ListItemText primary={newItemName} secondary={item} />;
		} else {
			const formattedDate = moment(subscriptionDetails[itemName]).utc().format('MMMM, DD YYYY');

			switch (itemName) {
				case 'createdAt':
					listItemFormatted = <ListItemText primary={newItemName} secondary={formattedDate} />;
					break;
				case 'modifiedAt':
					listItemFormatted = <ListItemText primary={newItemName} secondary={formattedDate} />;
					break;
				default:
					listItemFormatted = (
						<ListItemText primary={newItemName} secondary={subscriptionDetails[itemName]} />
					);
					break;
			}
		}

		return listItemFormatted;
	};

	const goToBoxInfo = (boxDetails) => {
		navigate(
			`/app/box-details/?boxId=${boxDetails.id}&backRoute=subscription-details/?subscriptionId=${boxDetails.subscriptionId}`,
			{
				state: {
					boxId: boxDetails.id,
					backRoute: `/app/subscription-details/?subscriptionId=${boxDetails.subscriptionId}`
				}
			}
		);
	};

	const addOns =
		subscriptionDetails &&
		subscriptionDetails.prices
			.map((price) => {
				if (Boolean(price.price.metadata.addOn)) {
					return `- Qty. ${price.quantity}: ${price.price.metadata.tradename}`;
				} else {
					return null;
				}
			})
			.filter((price) => price);
	let subscriptionDetailsComponent = null;

	if (loadingSubscription) {
		subscriptionDetailsComponent = (
			<Container className={classes.rootLoading}>
				<CircularProgress className={classes.spacing} />
				<Typography className={classes.spacing} variant="caption" component="div">
					Loading Subscription data...
				</Typography>
			</Container>
		);
	} else {
		if (subscriptionDetails) {
			subscriptionDetailsComponent = (
				<Grid container spacing={1} justify="center">
					<Grid item md={4} xs={12}>
						<ItemHeader title="Subscription Details" />
						<List>
							{itemName.map((name, index) => {
								if (
									typeof subscriptionDetails[name] === 'string' ||
									typeof subscriptionDetails[name] === 'boolean'
								) {
									return <ListItem key={index}>{getDetailItem(name)}</ListItem>;
								} else {
									return null;
								}
							})}
						</List>
					</Grid>
					<Grid item md={4} xs={12}>
						<div className={classes.boxesContainer}>
							<ItemHeader title="Shipping Address" />
							<List>
								{Object.keys(subscriptionDetails.shippingAddress)
									.sort((a, b) => {
										if (a > b) {
											return 1;
										} else {
											return -1;
										}
									})
									.map((name, index) => {
										const newItemName = stringCapitalization(name);
										const content = subscriptionDetails.shippingAddress[name];
										return (
											<ListItemText
												key={index}
												primary={newItemName}
												secondary={newItemName === 'Geometry' ? 'None' : content}
											/>
										);
									})}
							</List>
						</div>
					</Grid>
					<Grid item md={4} xs={12}>
						<div className={classes.boxesContainer}>
							<ItemHeader title="Subscription Box" />
							<List cellHeight={80} className={classes.gridList} cols={4}>
								{subscriptionDetails.boxes.map((box, index) => (
									<ListItemText
										key={index}
										primary={<Button onClick={() => goToBoxInfo(box)}>{`- ID: ${box.id}`}</Button>}
									/>
								))}
							</List>
						</div>
						<div className={classes.boxesContainer}>
							<ItemHeader title="Subscription AddOns" />
							<List cellHeight={80} className={classes.gridList} cols={4}>
								{addOns.length > 0 ? (
									addOns.map((addOn, index) => {
										return <ListItemText key={index} primary={addOn} />;
									})
								) : (
									<Alert severity="error">This subscription not have Addons yet.</Alert>
								)}
							</List>
						</div>
					</Grid>
				</Grid>
			);
		}
	}

	return (
		<Container>
			<div className={classes.root}>
				<div className={classes.actionButons}>
					<Fab
						size="small"
						color="primary"
						aria-label="add"
						onClick={() => navigate(backRoute ? backRoute : '/app/box-list')}
					>
						<ArrowBackIcon />
					</Fab>
				</div>

				{subscriptionDetailsComponent}
			</div>
		</Container>
	);
};

export default SubscriptionDetails;
