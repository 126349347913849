export const HOME = '/';
export const APP = '/app';
export const LOGIN = `${APP}/login`;
export const ADMIN_USER_CLAIMS = `${APP}/admin-user-claims`;
export const BOX_LIST = `${APP}/box-list`;
export const BOX_LIST_SHIPPING = `${APP}/box-list-shipping`;
export const SUBSCRIPTIONS = `${APP}/subscription-list`;
export const BOX_DETAILS = `${APP}/box-details`;
export const SUBSCRIPTIONS_DETAILS = `${APP}/subscriptions-details`;
export const COUPONS_AND_PROMO_CODES = `${APP}/coupons-and-promo-codes`;
