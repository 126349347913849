import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DefaultButton from './buttons/defaultButton';
import { Typography } from './UIComponents';

const useStyles = makeStyles((theme) => ({
	filterSection: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
		width: '100%',
		backgroundColor: 'white',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.3)',
		justifyContent: 'center'
	}
}));

function EmptyList(props) {
	const classes = useStyles();
	const { getData } = props;

	return (
		<div className={classes.filterSection}>
			<Typography variant="h6" id="tableTitle" component="div" color="secondary">
				No data yet!
			</Typography>
			<DefaultButton title="Get Data" hideWrapper={true} buttonAction={getData} />
		</div>
	);
}

export default EmptyList;
