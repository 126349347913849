import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '../UIComponents';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'space-evenly',
		flexDirection: 'column',
		alignItems: 'center',
		'& > * + *': {
			marginLeft: theme.spacing(2)
		},
		height: '15vh'
	}
}));

function Loader(props) {
	const { message } = props;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CircularProgress />
			<Typography>{message}</Typography>
		</div>
	);
}

export default Loader;
