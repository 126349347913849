import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import CustomTextField from '../inputs/customTextField';
import DefaultButton from '../buttons/defaultButton';
import { updateBoxData } from '../../services/fetchService';
import { setBoxFeatureInfo, updateBoxesStore } from '../../services/storeUpdater';
import ErrorModal from './errorModal';
import SuccessModal from './successModal';
import * as actions from '../../store/boxes/action-types';

function getModalStyle() {
	const top = 50;
	const left = 50;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`
	};
}

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3)
	}
}));

function TrackingModal(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [modalStyle] = React.useState(getModalStyle);
	const { openModal, handleCloseModal, boxDetails, selectedAddon } = props;
	const boxes = useSelector((state) => state.boxes);

	const [trackingId, setTrackingId] = useState('');
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const updateAddonTrackingInfo = async () => {
		setLoading(true);
		const newPricesData = boxDetails.prices.map((price) => {
			if (price.price.id === selectedAddon.priceId) {
				return {
					...price,
					tracking: {
						tracking: true,
						trackingId: trackingId,
						trackingLink: `https://tools.usps.com/go/TrackConfirmAction.action?tLabels=${trackingId}`
					}
				};
			}

			return price;
		});

		const dataUpload = {
			input: [
				{
					id: boxDetails.id,
					prices: newPricesData
				}
			]
		};

		const boxUpdatedResponse = await updateBoxData(dataUpload);

		if (boxUpdatedResponse) {
			setLoading(false);
			if (typeof boxUpdatedResponse === 'string') {
				setErrorMessage(boxUpdatedResponse);
			} else {
				setShowSuccessModal(true);
				if (boxes) {
					const lastBoxesData = updateBoxesStore(boxes.boxesData, boxUpdatedResponse[0]);

					const updatedData = setBoxFeatureInfo(lastBoxesData, boxes.boxesData.batchNumber);

					if (updatedData) {
						dispatch({
							type: actions.SAVE_BOXES,
							payload: {
								boxes: updatedData,
								batch: boxes.batch
							}
						});
					}
				}
			}
		}
	};

	const body = (
		<div style={modalStyle} className={classes.paper}>
			<h2 id="simple-modal-title">Add tracking id to:</h2>
			<p>{selectedAddon.name}</p>
			<CustomTextField
				name="trackingId"
				label="Tracking Number"
				value={trackingId}
				handleChange={(event) => setTrackingId(event.target.value)}
			/>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<DefaultButton title="Cancel" color="secondary" buttonAction={handleCloseModal} disabled={loading} />
				<DefaultButton
					title="Add tracking"
					buttonAction={updateAddonTrackingInfo}
					disabled={trackingId.length < 22 || loading}
					loading={loading}
				/>
			</div>
		</div>
	);

	const errorModal = errorMessage.length > 0 && (
		<ErrorModal
			openModal={errorMessage.length > 0}
			handleCloseModal={() => setErrorMessage('')}
			message={errorMessage}
		/>
	);

	const successModal = showSuccessModal && (
		<SuccessModal
			openModal={showSuccessModal}
			handleCloseModal={() => {
				setShowSuccessModal(false);
				handleCloseModal();
			}}
			message="Tracking number was updated successfully."
		/>
	);

	return (
		<>
			{errorModal}
			{successModal}
			<Modal
				open={openModal}
				onClose={null}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				{body}
			</Modal>
		</>
	);
}

export default TrackingModal;
