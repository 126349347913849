import { Tooltip } from '../../components/UIComponents';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	rootLoading: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		background: theme.palette.background.paper
	},
	spacing: {
		marginBottom: theme.spacing(2)
	},
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
		marginTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},
	button: {
		margin: theme.spacing(1),
		marginLeft: theme.spacing(4)
	},
	labelPhoto: {
		cursor: 'pointer'
	},
	noteInfo: {
		textAlign: 'center'
	},
	buttonPrint: {
		backgroundColor: 'transparent',
		border: 'none',
		outline: 'none',
		cursor: 'pointer'
	},
	titleBar: {
		backgroundColor: 'rgba(0,0,0,.6)',
		color: theme.palette.common.white,
		height: '30px',
		padding: theme.spacing(2)
	},
	icon: {
		color: 'white'
	},
	boxesContainer: {
		paddingBottom: theme.spacing(2)
	},
	featuresActions: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}));

export const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11
	}
}))(Tooltip);
