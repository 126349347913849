import { Tooltip } from '../../components/UIComponents';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	rootLoading: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center'
	},
	spacing: {
		marginBottom: theme.spacing(2)
	},
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
		marginTop: theme.spacing(2)
	},
	button: {
		margin: theme.spacing(1),
		marginLeft: theme.spacing(4)
	},
	imageButton: {
		cursor: 'pointer'
	},
	extendedIcon: {
		marginLeft: theme.spacing(1)
	},
	actionButons: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	boxesContainer: {
		paddingBottom: theme.spacing(2)
	},
	gridList: {
		paddingRight: theme.spacing(2)
	}
}));

export const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11
	}
}))(Tooltip);
