import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Select } from '../UIComponents';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
		minWidth: 200
	}
}));

function CustomSelect({ name, label, options, value, handleChange, disabled, handleOpen }) {
	const classes = useStyles();

	return (
		<FormControl className={classes.formControl}>
			<InputLabel htmlFor={name}>{label}</InputLabel>
			<Select name={name} value={value} onChange={handleChange} disabled={disabled} onOpen={handleOpen}>
				{options.map((item, index) => {
					return (
						<MenuItem key={index} value={item.value}>
							{item.text}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}

export default CustomSelect;
