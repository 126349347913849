import axios from 'axios';
import moment from 'moment';
import { create } from 'apisauce';
import { signOut } from './authService';
import { getCookie } from './cookiesService';
import { USER_DATA } from '../constants/localStorage';
import jwtDecode from 'jwt-decode';

const API_URL = process.env.GATSBY_API_URL;

let apisauceInstance = null;

const customAxiosInstance = axios.create({
	baseURL: API_URL,
	headers: {
		'Content-Type': 'application/json'
	},
	timeout: 60000,
	withCredentials: false
});

const getConfig = async () => {
	const storageTokenId = await getCookie(USER_DATA);
	const expirationDate = storageTokenId && new Date(jwtDecode(storageTokenId).exp * 1000);

	const isValidToken = moment().isBefore(expirationDate);
	let TOKEN_ID = null;

	if (isValidToken) {
		TOKEN_ID = storageTokenId;
	} else {
		signOut();
	}

	if (TOKEN_ID) {
		customAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${TOKEN_ID}`;

		apisauceInstance = create({ axiosInstance: customAxiosInstance });

		return true;
	}
};

export const graphqlServiceAuthSauce = async (query, variables) => {
	const instanceReady = await getConfig();

	if (instanceReady) {
		try {
			const httpResquest = await apisauceInstance.post('/graphql', {
				query: query,
				variables: variables
			});

			return httpResquest;
		} catch (error) {
			return error;
		}
	}
};

export const graphqlServiceSauce = async (query, variables) => {
	apisauceInstance = create({ axiosInstance: customAxiosInstance });

	try {
		const httpResquest = await apisauceInstance.post('/graphql', {
			query: query,
			variables: variables
		});
		return httpResquest;
	} catch (error) {
		return error;
	}
};
