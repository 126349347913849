import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomSelect from './inputs/customSelect';
import CustomTextField from './inputs/customTextField';
import DefaultButton from './buttons/defaultButton';
import * as CONSTANTS from '../constants/universal';

const useStyles = makeStyles((theme) => ({
	filterSection: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
		width: '100%',
		backgroundColor: 'white',
		display: 'flex',
		alignItems: 'center',
		boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.3)'
	},
	buttonSpacer: {
		marginLeft: theme.spacing(10),
		textAlign: 'center'
	},
	checkBoxContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: 235,
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	}
}));

function Filters(props) {
	const classes = useStyles();
	const { handleChange, batch, formState, filter, clearFilters, getData, simple } = props;

	return (
		<div className={classes.filterSection}>
			{filter === 'boxes' ? (
				<CustomSelect
					name="batch"
					label="Select a batch"
					options={CONSTANTS.FILTER_BATCHES}
					value={batch}
					handleChange={handleChange}
				/>
			) : (
				<CustomTextField
					name="subscriptionId"
					label="Subscription Id"
					value={formState.subscriptionId}
					handleChange={handleChange}
				/>
			)}
			<div className={classes.checkBoxContainer}>
				<CustomSelect
					name="status"
					label="Select a Status"
					options={
						filter !== 'subscriptions'
							? simple
								? CONSTANTS.BOX_STATUSES_SIMPLE
								: CONSTANTS.BOX_STATUSES
							: CONSTANTS.SUBSCRIPTION_STATUSES
					}
					value={formState.status}
					handleChange={handleChange}
				/>
				{filter === 'boxes' && (
					<CustomSelect
					name="product"
					label="Select a Type Product"
					options={CONSTANTS.PRODUCTS_TYPES}
					value={formState.product}
					handleChange={handleChange}
					/>
				)}
			</div>
			<div className={classes.checkBoxContainer}>
				<CustomTextField
					name="ownerEmail"
					label="Subscriber Email"
					value={formState.ownerEmail}
					handleChange={handleChange}
				/>
				<CustomTextField
					name="subscriber"
					label="Subscriber Name"
					value={formState.subscriber}
					handleChange={handleChange}
				/>
			</div>
			<div className={classes.checkBoxContainer}>
				{filter === 'boxes' && (
					<CustomSelect
						name="tracking"
						label="With Tracking"
						options={CONSTANTS.YES_NOT_OPTIONS}
						value={formState.tracking}
						handleChange={handleChange}
					/>
				)}
				<CustomSelect
					name="withAddOns"
					label={filter === 'boxes' ? 'With AddOns' : 'With AddOns'}
					options={CONSTANTS.YES_NOT_OPTIONS}
					value={formState.withAddOns}
					handleChange={handleChange}
				/>
			</div>
			<div className={classes.buttonSpacer}>
				<DefaultButton title="Get Data" hideWrapper={true} buttonAction={getData} />
				<DefaultButton title="Clean Filters" hideWrapper={true} buttonAction={clearFilters} color="secondary" />
			</div>
		</div>
	);
}

export default Filters;
