import React, { useEffect } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { Router, navigate } from '@reach/router';
import PrivateRoute from '../components/privateRoute';
import AdminUserClaims from '../components/screens/adminUserClaims';
import Login from '../components/screens/login';
import BoxList from '../components/screens/boxList';
import BoxListSimple from '../components/screens/boxListSimple';
import Subscriptions from '../components/screens/subscriptions';
import BoxDetails from '../components/screens/boxDetails';
import SubscriptionDetails from '../components/screens/subscriptionsDetails';
import CouponsAndPromoCodes from '../components/screens/couponsAndPromoCodes';
import { isLoggedIn } from '../services/authService';
import * as actions from '../store/user/action-types';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#205408'
		},
		secondary: {
			main: '#f0b625'
		}
	}
});

const App = (props) => {
	const dispatch = useDispatch();

	useEffect(() => {
		const checkLoggedIn = async () => {
			const user = await isLoggedIn();

			if (user) {
				dispatch({ type: actions.SAVE_USER, payload: user });
				navigate(`${props.location.pathname}${props.location.search}`);
			} else {
				navigate('login');
			}
		};

		checkLoggedIn();
	}, [dispatch, props.location.pathname, props.location.search]);

	return (
		<ThemeProvider theme={theme}>
			<Router basepath="/app">
				<Login path="/login" />
				<PrivateRoute path="/admin-user-claims" component={AdminUserClaims} />
				<PrivateRoute path="/box-list" component={BoxList} />
				<PrivateRoute path="/box-list-shipping" component={BoxListSimple} />
				<PrivateRoute path="/coupons-and-promo-codes" component={CouponsAndPromoCodes} />
				<PrivateRoute path="/subscription-list" component={Subscriptions} />
				<PrivateRoute path="/box-details" component={BoxDetails} />
				<PrivateRoute path="/subscription-details" component={SubscriptionDetails} />
			</Router>
		</ThemeProvider>
	);
};

export default App;
