import React, { useEffect, useReducer, useState } from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../utilities/loader';
import Filters from '../filters';
import EmptyList from '../emptyList';
import CustomTable from '../utilities/customTable';
import SubscriptionBoxList from '../../components/screens/subscriptionBoxList';
import {
	removeLocalStorageItem,
	getLocalStorageItem,
	setLocalStorageItem,
	mergeDataToLocalStorage
} from '../../services/localStorageService';
import { getSubscriptionList } from '../../services/fetchService';
import {
	filteringData,
	setBoxFeatureInfo,
	setSubscriptionsFeatureInfo,
	doExportToExcel
} from '../../services/storeUpdater';
import * as CONSTANTS from '../../constants/universal';
import { CUSTOM_TABLE_SORTS, SUBSCRIPTION_LIST_FILTERS } from '../../constants/localStorage';
import * as actions from '../../store/subscriptions/action-types';

const headCells = [
	{ id: 'more', numeric: false, disablePadding: true, label: 'More' },
	{ id: 'id', numeric: false, disablePadding: true, label: 'Subscription Id' },
	{ id: 'ownerEmail', numeric: false, disablePadding: true, label: 'Email' },
	{ id: 'status', numeric: false, disablePadding: true, label: 'Status' },
	{ id: 'createdAt', numeric: false, disablePadding: false, label: 'Created At' },
	{ id: 'description', numeric: false, disablePadding: false, label: 'Type' },
	{ id: 'withAddOns', numeric: false, disablePadding: false, label: 'Has AddOns' },
	{ id: 'addOns', numeric: false, disablePadding: false, label: 'AddOns' }
];

const cellIndex = ['id', 'ownerEmail', 'status', 'createdAt', 'description', 'withAddOns', 'addOns'];

function Subscriptions() {
	const subscriptions = useSelector((state) => state.subscriptions.subscriptionsData);
	const dispatch = useDispatch();

	const [formState, setStateForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
		status: '',
		ownerEmail: '',
		subscriptionId: '',
		withAddOns: ''
	});
	const [tableSorts, setTableSorts] = useReducer((state, newState) => ({ ...state, ...newState }), {
		order: 'asc',
		orderBy: 'ownerEmail',
		page: 0
	});

	const [loadingSubscriptionsList, setLoadingSubscriptionsList] = useState(false);
	const [errorMessage, setErrorMessage] = useState();
	const [subscriptionList, setSubscriptionList] = useState([]);
	const [activateBoxesTable, setActivateBoxesTable] = useState(false);

	useEffect(() => {
		const sorts = getLocalStorageItem(CUSTOM_TABLE_SORTS);
		const lastListFilters = getLocalStorageItem(SUBSCRIPTION_LIST_FILTERS);

		if (sorts) {
			setTableSorts({
				...sorts
			});
		}

		if (lastListFilters) {
			if (lastListFilters.ownerEmail.length > 0) {
				setActivateBoxesTable(true);
			}
			setStateForm({
				...lastListFilters
			});
		}
	}, []);

	useEffect(() => {
		const doFilteringSubscriptions = () => {
			const filteredSubscriptions = filteringData(formState, subscriptions);

			setSubscriptionList(filteredSubscriptions);
		};

		if (subscriptions) {
			doFilteringSubscriptions();
		}
	}, [dispatch, subscriptions, formState]);

	const getSubscriptions = async () => {
		setLoadingSubscriptionsList(true);

		let variable = {
			filter: {}
		};

		if (formState.ownerEmail !== '') {
			variable.filter.ownerEmail = formState.ownerEmail;
		}

		if (formState.subscriptionId !== '') {
			variable.filter.id = formState.subscriptionId;
		}

		if (formState.status !== '') {
			variable.filter.status = formState.status;
		}

		const subscriptionsListResponse = await getSubscriptionList(variable);

		if (subscriptionsListResponse) {
			setLoadingSubscriptionsList(false);

			if (typeof subscriptionsListResponse === 'string') {
				setErrorMessage(subscriptionsListResponse);
			} else {
				const subscriptionsList = setSubscriptionsFeatureInfo(subscriptionsListResponse);

				console.log(subscriptionsList);
				setSubscriptionList(subscriptionsList);
				dispatch({ type: actions.SAVE_SUBSCRIPTIONS, payload: subscriptionsList });
			}
		}
	};

	const handleChange = (event) => {
		const name = event.target.name;
		let value = event.target.value;

		if (name === 'ownerEmail') {
			value = value.trim().toLowerCase();
		}

		mergeDataToLocalStorage(SUBSCRIPTION_LIST_FILTERS, {
			[name]: value
		});

		setTableSorts({
			order: 'asc',
			orderBy: 'ownerEmail',
			page: 0
		});

		setStateForm({
			[name]: value
		});
	};

	const clearFilters = () => {
		setStateForm({
			status: CONSTANTS.SUBSCRIPTION_STATUSES[0].value,
			ownerEmail: '',
			subscriptionId: '',
			withAddOns: ''
		});
		setTableSorts({
			order: 'asc',
			orderBy: 'ownerEmail',
			page: 0
		});
		removeLocalStorageItem(CUSTOM_TABLE_SORTS);
		removeLocalStorageItem(SUBSCRIPTION_LIST_FILTERS);
	};

	const refreshSubscriptionList = () => {
		getSubscriptions();
	};

	const goToSubscriptionInfo = (event, subscriptionDetails) => {
		navigate(`/app/subscription-details/?subscriptionId=${subscriptionDetails.id}&backRoute=subscription-list`, {
			state: { boxId: subscriptionDetails.id, backRoute: '/app/subscription-list' }
		});
	};

	const handleTableSorts = (data) => {
		const newTableSorts = Object.assign(tableSorts, data);

		setLocalStorageItem(CUSTOM_TABLE_SORTS, newTableSorts);

		setTableSorts(newTableSorts);
	};

	const exportToExcel = () => {
		doExportToExcel(cellIndex, subscriptionList, 'Survivalbox Subscriptions List');
	};

	let boxesListSection = null;
	let loader = null;
	let table = null;
	let message = null;

	if (loadingSubscriptionsList) {
		loader = <Loader message="Loading Subscriptions, please wait..." />;
	} else {
		if (errorMessage) {
			message = null;
		} else {
			if (subscriptionList.length > 0) {
				table = (
					<>
						<CustomTable
							rows={subscriptionList}
							tableTitle="Subscriptions List"
							headCells={headCells}
							cellIndex={cellIndex}
							handleClickRow={goToSubscriptionInfo}
							refreshList={refreshSubscriptionList}
							tableSorts={tableSorts}
							handleTableSorts={handleTableSorts}
							exportToExcel={exportToExcel}
						/>
					</>
				);

				const boxesList = subscriptionList.map((subscription) => {
					const newBoxesList = subscription.boxes.map((box) => {
						return {
							...box,
							boxRoot: {
								shippingAddress: subscription.shippingAddress,
								status: subscription.status
							},
							addOns: subscription.upgrades
						};
					});

					return {
						boxes: setBoxFeatureInfo(newBoxesList),
						subscriptionId: subscription.id
					};
				});

				if (activateBoxesTable) {
					if (boxesList.length < 10) {
						boxesListSection = boxesList.map((boxesData, index) => {
							return <SubscriptionBoxList key={index} boxesData={boxesData} />;
						});
					}
				}
			} else {
				table = <EmptyList getData={getSubscriptions} />;
			}
		}
	}

	return (
		<div>
			<Filters
				handleChange={handleChange}
				formState={formState}
				filter="subscriptions"
				clearFilters={clearFilters}
				getData={getSubscriptions}
			/>
			{table}
			{boxesListSection}
			{loader}
			{message}
		</div>
	);
}

export default Subscriptions;
