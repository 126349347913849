import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import DefaultButton from '../buttons/defaultButton';

function getModalStyle() {
	const top = 50;
	const left = 50;
	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`
	};
}

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3)
	}
}));

function ConfirmModal(props) {
	const classes = useStyles();
	const [modalStyle] = React.useState(getModalStyle);
	const { openModal, handleCloseModal, message, handleConfirmAction } = props;

	const body = (
		<div style={modalStyle} className={classes.paper}>
			<h2 id="simple-modal-title">Confirmation</h2>
			<p id="simple-modal-description">{message}</p>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<DefaultButton title="Cancel" color="secondary" buttonAction={handleCloseModal} />
				<DefaultButton title="Ok" buttonAction={handleConfirmAction} />
			</div>
		</div>
	);

	return (
		<Modal
			open={openModal}
			onClose={handleCloseModal}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			{body}
		</Modal>
	);
}

export default ConfirmModal;
