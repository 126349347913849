import React, { useReducer } from 'react';
import { navigate } from 'gatsby';
import CustomTable from '../utilities/customTable';
import { setLocalStorageItem } from '../../services/localStorageService';
import { CUSTOM_TABLE_SORTS } from '../../constants/localStorage';

const headCells = [
	{ id: 'seeMore', numeric: false, disablePadding: false, label: 'More' },
	{ id: 'ownerEmail', numeric: false, disablePadding: true, label: 'Owner Email' },
	{ id: 'boxTheme', numeric: false, disablePadding: false, label: 'Current Theme Number' },
	{ id: 'subscriber', numeric: false, disablePadding: true, label: 'Subscriber' },
	{ id: 'id', numeric: false, disablePadding: true, label: 'Box Id' },
	{ id: 'deliverDate', numeric: false, disablePadding: true, label: 'Deliver Date' },
	{ id: 'status', numeric: false, disablePadding: false, label: 'Box Status' },
	{ id: 'product', numeric: false, disablePadding: false, label: 'Product' },
	{ id: 'addOns', numeric: false, disablePadding: false, label: 'AddOns' },
	{ id: 'wellcomeBox', numeric: false, disablePadding: false, label: 'Welcome Box' },
	{ id: 'batchNumber', numeric: true, disablePadding: false, label: 'Batch Number' },
	{ id: 'folderItem', numeric: true, disablePadding: false, label: 'Folder Item' }
];

const cellIndex = [
	'ownerEmail',
	'boxTheme',
	'subscriber',
	'id',
	'deliverDate',
	'status',
	'product',
	'addOns',
	'wellcomeBox',
	'batchNumber',
	'folderItem'
];

function SubscriptionBoxList({ boxesData }) {
	const boxList = boxesData.boxes;

	const [tableSorts, setTableSorts] = useReducer((state, newState) => ({ ...state, ...newState }), {
		order: 'asc',
		orderBy: 'ownerEmail',
		page: 0
	});

	const goToBoxInfo = (event, boxDetails) => {
		navigate(`/app/box-details/?boxId=${boxDetails.id}&backRoute=subscription-list`, {
			state: { boxId: boxDetails.id, backRoute: '/app/subscription-list' }
		});
	};

	const handleTableSorts = (data) => {
		const newTableSorts = Object.assign(tableSorts, data);

		setLocalStorageItem(CUSTOM_TABLE_SORTS, newTableSorts);

		setTableSorts(newTableSorts);
	};

	let loader = null;
	let table = null;

	if (boxList) {
		table = (
			<CustomTable
				rows={boxList}
				tableTitle={`Boxes of subscription: ${boxesData.subscriptionId}`}
				headCells={headCells}
				cellIndex={cellIndex}
				handleClickRow={goToBoxInfo}
				tableSorts={tableSorts}
				handleTableSorts={handleTableSorts}
				hideRefresh={true}
			/>
		);
	}

	return (
		<div>
			{table}
			{loader}
		</div>
	);
}

export default SubscriptionBoxList;
