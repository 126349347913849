import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
	Drawer,
	List,
	ChevronLeftIcon,
	ChevronRightIcon,
	ListAltIcon,
	SubscriptionsIcon,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	IconButton,
	ContactsIcon,
	CardGiftcardIcon
} from '../UIComponents';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { setStackByUser } from '../../services/stackServices';
import * as ROUTES from '../../constants/routes';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap'
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(8) + 1
		}
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar
	}
}));

function CustomDrawer(props) {
	const { handleDrawerClose, open, location } = props;
	const userData = useSelector((state) => state.user.userData);

	const classes = useStyles();
	const theme = useTheme();

	let drawerRoutes = [];

	if (userData?.roles) {
		drawerRoutes = setStackByUser(userData.roles);
	}

	const getIcon = (name) => {
		let icon = null;

		switch (name) {
			case ROUTES.BOX_LIST:
				icon = <ListAltIcon />;
				break;
			case ROUTES.BOX_LIST_SHIPPING:
				icon = <ListAltIcon />;
				break;
			case ROUTES.SUBSCRIPTIONS:
				icon = <SubscriptionsIcon />;
				break;
			case ROUTES.ADMIN_USER_CLAIMS:
				icon = <ContactsIcon />;
				break;
			case ROUTES.COUPONS_AND_PROMO_CODES:
				icon = <CardGiftcardIcon />;
				break;
			default:
				break;
		}

		return icon;
	};

	const doNavigation = (route) => {
		if (route === 'back') {
			navigate('/app/box-list');
		} else {
			navigate(route);
		}
	};

	const drawerClassNames = `${classes.drawer} ${open ? classes.drawerOpen : classes.drawerClose}`;
	const drawerClasses = {
		paper: open ? classes.drawerOpen : classes.drawerClose
	};

	return (
		<Drawer anchor="left" variant="permanent" className={drawerClassNames} classes={drawerClasses}>
			<div className={classes.toolbar}>
				<IconButton onClick={handleDrawerClose}>
					{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
				</IconButton>
			</div>
			<Divider />
			{drawerRoutes.map((list, index) => {
				return (
					<List key={index}>
						{list.map((route, index) => {
							const icon = getIcon(route.path);

							return (
								<ListItem
									selected={location.pathname === route.path}
									button
									key={index}
									onClick={() => doNavigation(route.path)}
								>
									<ListItemIcon>{icon}</ListItemIcon>
									<ListItemText primary={route.text} />
								</ListItem>
							);
						})}

						<Divider />
					</List>
				);
			})}
		</Drawer>
	);
}

export default CustomDrawer;
