import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getDataFilters } from './dataValidationService';

export const updateBoxesStore = (store, data) => {
	const storeRefreshed = store.map((box) => {
		if (box.id === data.id) {
			return {
				...data,
				boxRoot: box.boxRoot,
				batchNumber: box.batchNumber
			};
		} else {
			return box;
		}
	});

	return storeRefreshed;
};

export const setBoxFeatureInfo = (boxesList, batchNumber) => {
	const newShippingList = boxesList.map((item) => {
		const subscriber = `${item.boxRoot?.shippingAddress?.firstName} ${item.boxRoot?.shippingAddress?.lastName}`;
		const addOns = item.prices
			? item.prices
					.map((price) => {
						if (Boolean(price.price.metadata.addOn)) {
							return {
								name: `- Qty. ${price.quantity}: ${price.price.metadata.tradename}`,
								tracking: price.tracking,
								priceId: price.price.id
							};
						} else {
							return null;
						}
					})
					.filter((price) => price)
			: [];

		return {
			batchNumber: batchNumber,
			folderItem: item.folderItemNumber,
			subscriber: subscriber,
			subscriptionStatus: item.boxRoot?.status,
			...item,
			boxMonth: Number(item.boxMonth),
			addOns: addOns,
			product: item.boxRoot?.description,
			withAddOns: addOns.length === 0 ? false : true,
			boxTheme: item.boxTheme ? item.boxTheme : 'N/A',
			nextBoxTheme: item.boxTheme + 1
		};
	});

	return newShippingList;
};

export const setSubscriptionsFeatureInfo = (subscriptionLList) => {
	const newSubscriptionList = subscriptionLList.map((item) => {
		const addOns = item.prices
			.map((price) => {
				if (Boolean(price.price.metadata.addOn)) {
					return `- Qty. ${price.quantity}: ${price.price.metadata.tradename}`;
				} else {
					return null;
				}
			})
			.filter((price) => price);

		return {
			...item,
			addOns: addOns
		};
	});

	return newSubscriptionList;
};

export const filteringData = (filters, data) => {
	const dataFilters = getDataFilters(filters);
	const filterKeys = Object.keys(dataFilters);

	const filteredData = data.filter((item) => {
		return filterKeys.every((key) => {
			if (typeof filters[key] === 'boolean') {
				if (key === 'tracking') {
					if (filters[key]) {
						return item.tracking['trackingId'].length > 3;
					} else {
						return item.tracking['trackingId'].length <= 3;
					}
				}

				return item[key] === filters[key];
			}

			if (key === 'product') {
				return (
					item.boxRoot.description.toLowerCase().includes(filters[key][0]) &&
					item.boxRoot.description.toLowerCase().includes(filters[key][1])
				);
			}

			if (key === 'subscriber') {
				const subscriberName = filters[key].toLowerCase().replace(/\s+/g, '');
				const itemSubscriber = item.subscriber.toLowerCase().replace(/\s+/g, '');
				if (subscriberName === itemSubscriber) {
					return item.subscriber;
				}
			}

			if (!filters[key].length) return true;

			return item[key] === filters[key];
		});
	});

	return filteredData;
};

export const doExportToExcel = (header, data, title) => {
	const excelData = getRowsExcelData(header, data);

	const wb = XLSX.utils.book_new();
	wb.SheetNames.push('Batch');

	const ws = XLSX.utils.json_to_sheet(excelData, { header: header });

	wb.Sheets['Batch'] = ws;

	const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

	saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${title}.xlsx`);
};

const s2ab = (s) => {
	var buf = new ArrayBuffer(s.length);
	var view = new Uint8Array(buf);
	for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
	return buf;
};

const getRowsExcelData = (header, data) => {
	const excelData = data.map((item, index) => {
		const rowValues = {};
		for (const key of header) {
			if (key === 'addOns') {
				rowValues[key] = item[key].join(', ');
			} else {
				rowValues[key] = item[key];
			}
		}

		return rowValues;
	});

	return excelData;
};

export const generateCouponsList = (couponsList) => {
	const newCouponsList = couponsList.map((coupon) => {
		return {
			...coupon,
			promotionCodes: coupon.promotionCodes.map((codes) => {
				return `- ${codes.code}  (${codes.times_redeemed} times)`;
			})
		};
	});

	return newCouponsList;
};

export const setBoxDetailsData = (boxDetails) => {
	const subscriber = `${boxDetails.boxRoot.shippingAddress.firstName} ${boxDetails.boxRoot.shippingAddress.lastName}`;
	const addOns = boxDetails.prices
		.map((price) => {
			if (Boolean(price.price.metadata.addOn)) {
				return {
					name: `- Qty. ${price.quantity}: ${price.price.metadata.tradename}`,
					tracking: price.tracking,
					priceId: price.price.id
				};
			} else {
				return null;
			}
		})
		.filter((price) => price);

	const newBoxDetails = {
		batchNumber: boxDetails.batchNumber,
		folderItem: boxDetails.folderItemNumber,
		subscriber: subscriber,
		subscriptionStatus: boxDetails.boxRoot.status,
		...boxDetails,
		boxMonth: Number(boxDetails.boxMonth),
		addOns: addOns,
		product: boxDetails.boxRoot.description,
		withAddOns: addOns.length === 0 ? false : true,
		boxTheme: boxDetails.boxTheme ? boxDetails.boxTheme : 'N/A',
		nextBoxTheme: boxDetails.boxTheme + 1
	};

	return newBoxDetails;
};
